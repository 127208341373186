import React from 'react';
import Header from './Header';
import Footer from './Footer';
import appDevImg from './Images/Télephone.jpg';
import webAppImg from './Images/gbtP6.jpg';
import websiteImg from './Images/gbtP3.jpg';
import networkImg from './Images/gbtP5.jpg';
import softwareImg from './Images/gbtP4.jpg';
import './services.css';

function services() {
  return (
   <div> <Header/>     
        <div className="header-text">
          <h1>Notre expertise</h1>
          <p>Nous offrons une large gamme de services pour les entreprises de toutes tailles et de tous secteurs. Nous avons une expertise approfondie en matière de développement d'applications mobiles, d'application web, de site internet, de logiciels de gestion et d'installation et de configuration de réseaux d'entreprise. </p>
        </div>
      <div className="services">
        <div className="service-item">
          <img src={appDevImg} alt="Application Development" />
          <div className="service-text">
            <h2>Développement d'applications mobiles</h2>
            <p>Nous sommes spécialisés dans le développement d'applications mobiles pour iOS et Android. Nous travaillons avec vous pour comprendre vos besoins et créer une application personnalisée qui répond à vos besoins métier. </p>
            <ul>
              <li>Expérience utilisateur exceptionnelle</li>
              <li>Conception centrée sur l'utilisateur</li>
              <li>Intégration de la technologie de pointe</li>
              <li>Support et maintenance continus</li>
            </ul>
          </div>
        </div>
        <div className="service-item">
          <img src={webAppImg} alt="Web Application Development" />
          <div className="service-text">
            <h2>Développement d'applications web</h2>
            <p>Nous créons des applications web sur mesure qui répondent aux besoins de votre entreprise. Nous nous concentrons sur l'expérience utilisateur, la performance et la sécurité pour garantir que votre application est facile à utiliser et à entretenir. </p>
            <ul>
              <li>Conception personnalisée</li>
              <li>Performances exceptionnelles</li>
              <li>Compatibilité multiplateforme</li>
              <li>Sécurité renforcée</li>
            </ul>
          </div>
        </div>
        <div className="service-item">
          <img src={websiteImg} alt="Website Design" />
          <div className="service-text">
            <h2>Conception de sites web</h2>
            <p>Nous créons des sites web attrayants et performants pour les entreprises de toutes tailles. Nous nous concentrons sur la conception de sites web responsives qui sont optimisés pour les appareils mobiles, ce qui garantit que votre site est facile à utiliser pour vos clients. </p>
            <ul>
              <li>Conception de sites web responsives</li>
              <li>Optimisation du référencement</li>
              <li>Intégration des réseaux sociaux</li>
              <li>Analyse de l'expérience utilisateur</li>
            </ul>
          </div>
        </div>
        <div className="service-item">
        <img src={networkImg} alt="Network Installation and Configuration" />
        <div className="service-text">
          <h2>Installation et configuration de réseaux d'entreprise</h2>
          <p>Nous pouvons vous aider à installer et configurer votre réseau d'entreprise pour garantir une connectivité fluide et sans interruption. Nous pouvons également fournir des services de support pour assurer un fonctionnement sans faille de votre réseau. </p>
          <ul>
            <li>Installation et configuration de réseaux LAN et WAN</li>
            <li>Sécurité réseau renforcée</li>
            <li>Support continu</li>
            <li>Formation de l'utilisateur</li>
          </ul>
        </div>
      </div>
      <div className="service-item">
        <img src={softwareImg} alt="Software Development" />
        <div className="service-text">
          <h2>Développement de logiciels de gestion</h2>
          <p>Nous développons des logiciels de gestion sur mesure pour les entreprises de toutes tailles. Nous nous concentrons sur la création de solutions logicielles efficaces qui automatisent les processus métier et améliorent l'efficacité de votre entreprise. </p>
          <ul>
            <li>Conception personnalisée de logiciels</li>
            <li>Automatisation des processus métier</li>
            <li>Intégration des systèmes existants</li>
            <li>Formation de l'utilisateur</li>
          </ul>
        </div>
      </div>
    </div>
  
    <Footer/>
  </div>
  );
}

export default services;
