import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './blog.css';

function Blog() {
  return (
    <div className="blog-container">
      <Header />
      <div className="blog-content">
        <h1>Nom du Blog</h1>
        <p>Description du Blog</p>
        <div className="blog-posts">
          <div className="blog-post">
            <img src="https://via.placeholder.com/300x200" alt="Placeholder" />
            <h2>Titre de l'article 1</h2>
            <p>Date de publication</p>
            <p>Description de l'article</p>
            <a href="#">Lire plus</a>
          </div>
          <div className="blog-post">
            <img src="https://via.placeholder.com/300x200" alt="Placeholder" />
            <h2>Titre de l'article 2</h2>
            <p>Date de publication</p>
            <p>Description de l'article</p>
            <a href="#">Lire plus</a>
          </div>
          <div className="blog-post">
            <img src="https://via.placeholder.com/300x200" alt="Placeholder" />
            <h2>Titre de l'article 3</h2>
            <p>Date de publication</p>
            <p>Description de l'article</p>
            <a href="#">Lire plus</a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
