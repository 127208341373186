import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="headerhead">
      <nav className="header-nav">
        <ul>
          <li><a href="/"><b>Accueil</b></a></li>
          <li><a href="services"><b>Services</b></a></li>
          <li><a href="portfolio"><b>Portefeuille</b></a></li>
          <li><a href="faq"><b>FAQ </b></a></li>
          <li><a href="register"><b>S'inscrire</b></a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
