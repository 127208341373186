import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './faq.css';

function FAQ() {
  return (
    <div><Header />
    <div className="faq-container">
      <h1>Foire Aux Questions</h1>
      <div className="faq-section">
        <h3>Qu'est-ce que votre entreprise fait exactement ?</h3>
        <p>Notre entreprise est spécialisée dans le développement de logiciels, la création de sites internet et d'applications mobiles, ainsi que la mise en place de réseaux d'entreprise.</p>
      </div>
      <div className="faq-section">
        <h3>Quelles sont vos références et expériences ?</h3>
        <p>Nous avons travaillé avec de nombreuses entreprises de différents secteurs d'activité, de la PME aux grands groupes. Vous pouvez consulter notre portfolio pour en savoir plus sur nos réalisations.</p>
      </div>
      <div className="faq-section">
        <h3>Quel est votre processus de travail ?</h3>
        <p>Nous suivons une méthode agile de développement, qui nous permet d'adapter notre travail aux besoins et aux évolutions du projet. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs attentes et leurs objectifs, et nous assurons une communication régulière tout au long du projet.</p>
      </div>
      <div className="faq-section">
        <h3>Quels sont vos tarifs ?</h3>
        <p>Nos tarifs varient en fonction de la complexité du projet et des services demandés. Nous proposons des devis sur mesure en fonction des besoins de chaque client. N'hésitez pas à nous contacter pour en savoir plus.</p>
      </div>
      <div className="faq-section">
        <h3>Comment se déroule le suivi après la livraison du projet ?</h3>
        <p>Nous assurons un suivi après la livraison du projet, pour garantir la satisfaction de nos clients et assurer la maintenance du produit. Nous proposons des contrats de maintenance et de support, ainsi que des formations pour permettre à nos clients de prendre en main leur outil.</p>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
