import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-nav">
      <div className="footer-partners">
      <h3></h3>
        <ul>
          <li><a href="./about">About</a></li>
          <li><a href="./services">Services</a></li>
          <li><a href="./faq">FAQ</a></li>
          <li><a href="./portfolio">Portfolio</a></li>
          <li><a href="register"><b>S'inscrire</b></a></li>
        </ul>
      </div>
      </div>
      <div className="footer-newsletter">
        <h3>Recevoir notre newsletter</h3>
        <form className="footer-newsletter-form">
          <input type="email" placeholder="Entrez votre email" />
          <input type="submit" value="S'abonner" />
        </form>
      </div>
      <div className="footer-partners">
      <h3></h3>
        <div className="footer-partners-logos">
          <img src="nsia-banque-logo.png" alt="NSIA Banque" />
          <img src="bicici-logo.png" alt="BICICI" />
          <img src="esn-logo.png" alt="ES&N Technologie" />
          <img src="educarriere-logo.png" alt="Educarrière" />
          <img src="cilog-logo.png" alt="Côte d'Ivoire Logistique" />
        </div>
      </div>
      <div className="footer-contact">
        <h3>Nous contacter</h3>
        <div className="footer-adress">
        GLOBAL BUSINESS AND TECHNOLOGY SARL 
        <br/>Siège Social : Abidjan cocody, carrefour adama sanogo 
        Côte d'Ivoire <br/>R.C. CI-GRDBSM-2021-A-3212  
        <br/>(+225) 0709046740 / 0707969324 / 0171775765
        </div>
        </div>
      <div className="footer-social-media">
        <h3>Nous suivre</h3>
        <div className="footer-social-media-logos">
          <a href="https://www.facebook.com/"><FaFacebook /></a>
          <a href="https://twitter.com/"><FaTwitter /></a>
          <a href="https://www.instagram.com/"><FaInstagram /></a>
          <a href="https://www.youtube.com/"><FaYoutube /></a>
        </div>
      </div>
    </footer>
  );
}


export default Footer;
